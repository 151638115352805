import { ChangeDetectionStrategy, Component, inject, input, InputSignal, signal, WritableSignal } from '@angular/core';

import { ModalService } from '../../services/modal.service';
import { NgClass } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
  animations: [
    trigger('modal', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          '250ms ease',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '250ms ease',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class ModalComponent<T> {
  public wide: InputSignal<boolean> = input<boolean>(false);
  public readonly display: WritableSignal<boolean> = signal(true);
  private readonly modalService: ModalService<T> = inject(ModalService<T>);

  public close(): void {
    this.display.set(false);

    setTimeout((): void => {
      this.modalService.close();
    }, 300);
  }
}
